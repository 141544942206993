import axios, { AxiosResponse } from 'axios';
import { createRef, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { invoiceEndpoints } from '../../../core/constants/endpoints';
import InvoicePaymentHeader from './PaymentHeader';
import InvoicePaymentInformation from './PaymentInformation';
import InvoicePaymentSummary from './PaymentSummary';
import PaymentError from './PaymentError';
import { IInvoiceResponse, IHTTPInterface, ITokenizerConfig } from '../../../core/interfaces/index';
import { useForm, FormProvider } from 'react-hook-form';
import PaymentSuccess from './PaymentSuccess';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { secCode } from '../../Global/Tokenizer/IqProACHTokenizer';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTheme } from '@mui/material';
import { stringFormat } from '../../Global/Tokenizer/tokenizerHelpers';

export interface ITokenInterface {
  token: string | undefined;
  expirationDate?: string | undefined;
  maskedNumber?: string;
  paymentType: string;
}
const InvoicePayment = () => {
  const methods = useForm();
  const { token } = useParams();
  const theme = useTheme();
  const recaptcha = createRef<ReCAPTCHA>();
  const [invoiceDetails, setInvoiceDetails] = useState<IInvoiceResponse | null>(null);
  const [tokenConfiguration, setTokenConfiguration] = useState<ITokenizerConfig | null>(null);
  const [invoicePaid, setInvoicePaid] = useState<boolean>(false);
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
  const [errorBanner, setErrorBanner] = useState<string>('');
  const { isValid } = methods.formState;
  const [errorToken, setErrorToken] = useState<number | null>(null);
  const [errorCount, setErrorCount] = useState<number>(0);

  useEffect(() => {
    retrieveTokenConfiguration();
  }, []);

  useEffect(() => {
    if (isValid && errorCount > 0) setErrorCount(errorCount - 1);
  }, [isValid]);

  const retrieveTokenConfiguration = () => {
    const url: string = invoiceEndpoints.invoices.invoice + token;
    axios
      .get(url, { headers: { Authorization: token } })
      .then((invoiceResponse: AxiosResponse<IHTTPInterface>) => {
        setInvoiceDetails(invoiceResponse?.data?.data);
        if (invoiceResponse?.data?.data?.remainingBalance === 0) {
          setInvoicePaid(true);
        }
        retrieveTokenizerConfiguration();
      })
      .catch((error) => {
        console.log(error);
        setErrorToken(error.response.status);
      });
  };

  const retrieveTokenizerConfiguration = () => {
    const url: string = stringFormat(invoiceEndpoints.tokenizationConfiguration, [token!]);
    axios
      .get(url, { headers: { Authorization: token } })
      .then((tokenResponse: AxiosResponse<IHTTPInterface>) => {
        setTokenConfiguration(tokenResponse?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        setErrorToken(error.response.status);
      });
  };

  const handlePayment = (tokenPayload: ITokenInterface, isFull: boolean) => {
    if (isValid) {
      recaptcha.current?.executeAsync().then(() => {
        setErrorBanner('');
        const addresses = formatAddress();
        const payload = {
          transactionPaymentMethod: {
            ...(tokenPayload.paymentType === 'card' && {
              card: {
                cardToken: tokenPayload.token,
                maskedNumber: tokenPayload.maskedNumber,
                expirationDate: tokenPayload?.expirationDate || null,
              },
            }),
            ...(tokenPayload.paymentType === 'ach' && {
              ach: {
                achToken: tokenPayload.token,
                secCode: secCode[invoiceDetails!.invoicePaymentMethod.secCode],
              },
            }),
          },
          transactionRemit: {
            baseAmount: isFull ? invoiceDetails?.remainingBalance : methods.getValues().paymentAmount,
            currencyCode: 'USD',
          },
          transactionAddress: addresses,
        };
        createTransaction(payload, isFull);
      });
    } else {
      setErrorBanner('Please fill all required fields.');
      setErrorCount(errorCount + 1);
    }
  };

  const formatAddress = () => {
    const addresses = [];
    if (invoiceDetails?.requireBillingInfo) {
      const billing = methods.getValues().billing;
      billing.isBilling = true;
      billing.country = invoiceDetails.invoiceAddresses.find((a) => a.isBilling)?.country || 'US';
      addresses.push(billing);
    }
    if (invoiceDetails?.requireShippingInfo) {
      const shipping = methods.getValues().shipping;
      shipping.isShipping = true;
      shipping.country = invoiceDetails.invoiceAddresses.find((a) => a.isShipping)?.country || 'US';
      addresses.push(shipping);
    }
    return addresses;
  };

  const createTransaction = (payload: object, isFull: boolean) => {
    setOpenBackdrop(true);
    const url: string = token + '/transaction';
    axios
      .post(url, payload, { headers: { Authorization: token } })
      .then((response: AxiosResponse<IHTTPInterface>) => {
        if (response?.data?.data?.responseCode !== 400 && response?.data?.data?.response.toLowerCase() !== 'declined') {
          invoiceDetails!.remainingBalance = response?.data?.data?.remainingBalance;
          if (!isFull) {
            invoiceDetails!.partialAmount = +methods.getValues().paymentAmount;
          }
          setInvoiceDetails(invoiceDetails);
          setInvoicePaid(true);
        } else {
          setErrorBanner('Transaction Declined: ' + response?.data?.data?.processorResponseText);
          setErrorCount(errorCount + 1);
        }
      })
      .catch(() => {
        setErrorBanner(
          'An error has prevented your payment from processing. Please check that your information is correct and try again.'
        );
        setErrorCount(errorCount + 1);
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  return (
    <FormProvider {...methods}>
      <form>
        <div>
          <Backdrop sx={{ color: '#fff', zIndex: 9000, opacity: 0.5 }} open={openBackdrop}>
            <CircularProgress color='inherit' />
          </Backdrop>
          {invoiceDetails !== null && tokenConfiguration !== null && (
            <>
              {invoicePaid ? (
                <PaymentSuccess invoiceDetail={invoiceDetails} invoiceToken={token} setErrorBanner={setErrorBanner} />
              ) : (
                <>
                  <InvoicePaymentHeader details={invoiceDetails} />
                  <div className='payment-body'>
                    <InvoicePaymentInformation
                      errorMessage={errorBanner}
                      errorCount={errorCount}
                      invoiceToken={token}
                      handlePayment={handlePayment}
                      invoiceDetail={invoiceDetails}
                      tokenConfiguration={tokenConfiguration}
                    />
                    <InvoicePaymentSummary invoiceDetails={invoiceDetails} />
                  </div>
                </>
              )}
            </>
          )}
          {errorToken && <PaymentError errorCode={errorToken} />}
        </div>
      </form>
      <ReCAPTCHA
        ref={recaptcha}
        theme={theme.palette.mode}
        size='invisible'
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''}
      />
    </FormProvider>
  );
};

export default InvoicePayment;
